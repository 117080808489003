import { Link } from 'react-router-dom';
import Accordion from '../../components/ui/Accordion';
import { LANGUAGE } from '../../constants/localStorage';
import { getData } from '../../services/storage';
import { faqData, faqDataBn } from '../../utils';
import { getLocaleString } from '../../utils/locales';

function FAQ() {
  const locale = getData(LANGUAGE);
  const data = locale === 'en' ? faqData : faqDataBn
  return (
    <div id="faq" className="mt-20">
      <p className="mb-12 text-center text-3xl font-semibold capitalize text-[#010103]/80 md:text-4xl">
        {getLocaleString(`frequently_asked_questions`, locale)}
      </p>
      <div className="flex items-center justify-center w-full px-9 md:px-24 lg:px-96">
        <div>
          {data.map((faq: any, index: any) => (
            
              <Accordion key={index} title={faq.title} body={faq.body} index={index} locale={locale} />
            
          ))}
          <div className="flex justify-center py-8">
            <Link
              to={`/${locale}/more-faq`}
              className="rounded-lg border border-[#CEC382] bg-gradient-to-r from-[#e9be5a] to-[#c6952c] hover:bg-gradient-to-br focus:outline-none py-4 px-14 text-base font-semibold text-primary hover:bg-secondary hover:text-white transition-all 4s ease-in-out"
            >
             {getLocaleString(`more_faq`, locale)}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
