
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";
import { LANGUAGE } from '../../constants/localStorage';
import { getData } from '../../services/storage';
import { privacyPolicySectionFourData, privacyPolicySectionFourDataBn, privacyPolicySectionOneData, privacyPolicySectionOneDataBn, privacyPolicySectionThreeData, privacyPolicySectionThreeDataBn, privacyPolicySectionTwoData, privacyPolicySectionTwoDataBn } from '../../utils/data/privacy_policy';
import { numberToAlphabet, romanize } from '../../utils/func';
import { getLocaleString } from '../../utils/locales';


export const PrivacyPolicy = () => {
  const locale = getData(LANGUAGE);
  const sectionOneData = locale == 'en' ? privacyPolicySectionOneData : privacyPolicySectionOneDataBn;
  const sectionTwoData = locale == 'en' ? privacyPolicySectionTwoData : privacyPolicySectionTwoDataBn;
  const sectionThreeData = locale == 'en' ? privacyPolicySectionThreeData : privacyPolicySectionThreeDataBn;
  const sectionFourData = locale == 'en' ? privacyPolicySectionFourData : privacyPolicySectionFourDataBn;

  return (
    <div className='px-5 text-justify pt-12 lg:pt-16 md:pt-16'>
    <h4 className="text-2xl font-semibold text-center my-5">{getLocaleString('privacy_policy', locale)}</h4>
    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
        {getLocaleString('privacy_policy_description', locale)}
    </ReactMarkdown>
    {
          sectionOneData.map((item, idx) => {
            return <>
              <li className="list-none pl-4">
                
                   
                      <ol className="py-2">
                        <span className="font-semibold">{romanize(idx+1)}.</span>	
                        {item.title}
                      </ol>
                      {item?.data && (
                        <li className="list-none pl-4">
                          {item.data.map((item, idx) => {
                            return <>
                              <ol className="py-2">
                                <span className="font-semibold">{romanize(idx+1)}.</span>
                                {item.title}

                              </ol>
                              {item?.children?.map((item, idx) => {
                                return <>
                                  <li className="list-none pl-4">
                                    <ol className="py-2">
                                      <span className="font-semibold">{romanize(idx+1)}.</span>	{item.title}</ol>
                                  </li>
                                  <li className="list-none pl-8">
                                  {item?.data.map((item, idx) => (
                                      <ol className="py-2">
                                        <span className="font-semibold">{romanize(idx+1)}.</span>	{item}
                                    </ol>
                                  ))}
                                  </li>
                                </>
                              })}
                            </>
                          })}
                        </li>
                      )}
                    
                  
                
                  
            </li>
            </>
            
        })
    }
    
    {
      sectionTwoData.map((item, idx) => {
        return <li className="list-none pl-4">
          <ol className="py-2">
              <span className="font-semibold">{romanize(idx+1)}.</span> {item.title}
          </ol>
          <li className="list-none pl-4">
            {item.data.map((item, idx) => {
              return <>
                <ol className="py-2">
                    <span className="font-semibold">{romanize(idx+1)}.</span> 	{item.title}
                </ol>
                <li className="list-none pl-4">
                  {item.data.map((item, idx) => (
                    <ol className="py-2">
                        <span className="font-semibold">{numberToAlphabet(idx+1)}.</span>	{item}
                    </ol>
                  ))}
                </li>
              </>
            })}
          </li>
        </li>
      })
    }
    
    <br></br>
    <p>{sectionThreeData.title}</p>
    <li className="list-none pl-10">
      {sectionThreeData.data.map((item, idx) => (
        <ol className="py-2">
            <span className="font-semibold">{numberToAlphabet(idx+1)}.</span>	{item}
        </ol>
      ))}
    </li>
    <br></br>

    <br></br>
    
    
    {sectionFourData.map((item, idx) => {
      return <>
      <p>{item.title}</p>
      <li className="list-none pl-4">
          {item?.data.map((item, idx) => {
            return <>
              <ol className="py-2">
                <span className="font-semibold">{romanize(idx+1)}.</span>	{item.title}
            </ol>
            <li className="list-none pl-4">
              {item?.children?.map((item, idx) => {
                return <>
                  <ol className="py-2">
                    <span className="font-semibold">{numberToAlphabet(idx+1)}.</span>	{item}
                </ol>
                </>
              })}
            
          </li>
            </>
          })}
      </li>
      <br></br>
        </>
      })}

</div>

  )
};
