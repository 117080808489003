import { useEffect } from 'react';
import ScrollToTop from './components/ScrollTotop';
import { LANGUAGE } from './constants/localStorage';
import useLanguageSwitch from './hooks/useLanguageSwitch';
import Routes from './routes/Routes';
import { getData } from './services/storage';
import './styles/global.css';
import './styles/tailwind.css';

function App() {
  const { handleLanguageToggle } = useLanguageSwitch();
  useEffect(() => {
    const locale = getData(LANGUAGE) ? getData(LANGUAGE)  : 'en'
    handleLanguageToggle(locale);
  }, []);
  
  return (
    <div>
      
      

      <ScrollToTop/>
        <Routes />
    </div>
  );
}

export default App;
