import React from 'react';
import { useRoutes } from 'react-router-dom';
import Layout from '../layout';
import AboutUs from '../pages/landing/AboutUs';
import ContactUs from '../pages/landing/ContactUs';
import { DeliveryProtocol } from '../pages/landing/DeliveryProtocol';
import { Eula } from '../pages/landing/Eula';
import FAQ from '../pages/landing/FAQ';
import GoldConvenience from '../pages/landing/goldConvenience';
import GoldKinen from '../pages/landing/GoldKinen';
import HowItWorks from '../pages/landing/HowItWorks';
import MoreFaq from '../pages/landing/MoreFaq';
import { PrivacyPolicy } from '../pages/landing/PrivacyPolicy';
import { RefundPolicy } from '../pages/landing/RefundPolicy';
import Services from '../pages/landing/Services';
import TermsOfUse from '../pages/landing/TermsOfUse';
import ScheduledCharges from '../pages/landing/charges';
// import TermsOfUse from '../pages/landing/TermsOfUse';

const Landing = React.lazy(() => import('../pages/landing'));
const NotFound = React.lazy(() => import('../pages/notFound'));

function Routes() {
  const routes = {
    path: '/',
    element: <Layout />,
    children: [
      { path: '/:lang', element: <Landing /> },
      { path: '/:lang/services', element: <Services /> },
      { path: '/:lang/gold-kinen', element: <GoldKinen /> },
      { path: '/:lang/about-us', element: <AboutUs /> },
      { path: '/:lang/faq', element: <FAQ /> },
      { path: '/:lang/contact-us', element: <ContactUs /> },
      { path: '/:lang/how-it-works', element: <HowItWorks /> },
      { path: '/:lang/goldConvenience', element: <GoldConvenience /> },
      { path: '/:lang/more-faq', element: <MoreFaq /> },
      { path: '/:lang/terms-of-use', element: <TermsOfUse /> },
      { path: '/:lang/privacy_policy', element: <PrivacyPolicy /> },
      { path: '/:lang/eula', element: <Eula /> },
      { path: '/:lang/refund_policy', element: <RefundPolicy /> },
      { path: '/:lang/delivery_protocol', element: <DeliveryProtocol /> },
      { path: '/:lang/scheduled-charges', element: <ScheduledCharges /> },
    ],
  };

  const notFound = {
    path: '/not-found',
    element: <NotFound />,
  };

  return useRoutes([routes, notFound]);
}

export default Routes;
