import ImageContainer from '../../components/ui/ImageContainer';
import { LANGUAGE } from '../../constants/localStorage';
import { getData } from '../../services/storage';
import { getLocaleString } from '../../utils/locales';

const Services = () => {
  const locale = getData(LANGUAGE);
  return (
    <div id="service" className='mt-5'>
      <div className="bg-[#D9D9D9]/10">
        <div className="flex items-center justify-center py-9 md:py-12 lg:py-16 ">
          <div>
            {/* <p className="text-[#010103]/54 my-4 text-center  text-base font-normal">GOLD KINEN</p> */}
            <p className="text-3xl font-semibold text-[#010103]/80 md:text-5xl">
              {getLocaleString('services_benefits', locale)}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-10 bg-white px-9 md:mt-20 md:px-20 lg:mt-8 lg:px-28">
        <div className="w-full px-0 lg:flex lg:py-10 lg:px-36">
          <div className="flex items-center justify-center w-full lg:w-1/2">
            <div className="">
              <ImageContainer src={'/image/services/BuyGold.jpg'} alt="service1" className="" />
            </div>
          </div>
          <div className="flex items-start w-full pt-6 pl-0 lg:w-1/2 lg:pt-20 lg:pl-16">
            <div>
              <p className="py-4 text-2xl font-semibold text-[#010103]/80">{getLocaleString('buy_gold', locale)}</p>
              <p className="text-[#010103]/54 text-base font-normal text-justify">
                {getLocaleString('buy_gold_description', locale)}
              </p>
            </div>
          </div>
        </div>

        <div className="space-y-8 pb-14 lg:flex lg:space-y-0 ">
          <div className="flex flex-col-reverse w-full px-0 lg:flex-row lg:px-36">
            <div className="w-full pt-6 lg:flex lg:w-1/2 lg:items-center lg:justify-center lg:pt-0">
              <div>
                <p className="py-4 text-2xl font-semibold text-[#010103]/80">{getLocaleString('sell_gold', locale)}</p>
                <p className="text-[#010103]/54 text-base font-normal text-justify">
                  {getLocaleString('sell_gold_description', locale)}
                </p>
              </div>
            </div>
            <div className="w-full space-y-8 lg:w-1/2 lg:space-y-0 lg:pl-16">
              <div className="w-full mt-12 lg:mt-0">
                <ImageContainer src={'/image/services/SellGold.jpg'} alt="service1" className="" />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full px-0 pt-6 pb-16 lg:flex lg:px-36">
          <div className="flex items-center justify-center w-full lg:w-1/2">
            <div className="">
              <ImageContainer src={'/image/services/CollectGold.jpg'} alt="service1" className="" />
            </div>
          </div>
          <div className="flex items-start w-full pt-6 pl-0 lg:w-1/2 lg:pt-20 lg:pl-16">
            <div>
              <p className="py-4 text-2xl font-semibold text-[#010103]/80">{getLocaleString('collect_gold', locale)}</p>
              <p className="text-[#010103]/54 text-base font-normal text-justify">
                {getLocaleString('collect_gold_description', locale)}
              </p>
            </div>
          </div>
        </div>

        <div className="space-y-8 pb-14 lg:flex lg:space-y-0 ">
          <div className="flex flex-col-reverse w-full px-0 lg:flex-row lg:px-36">
            <div className="w-full pt-6 lg:flex lg:w-1/2 lg:items-center lg:justify-center lg:pt-0">
              <div>
                <p className="py-4 text-2xl font-semibold text-[#010103]/80">{getLocaleString('gift_gold', locale)}</p>
                <p className="text-[#010103]/54 text-base font-normal text-justify">
                  {getLocaleString('gift_gold_description', locale)}
                </p>
              </div>
            </div>
            <div className="w-full space-y-8 lg:w-1/2 lg:space-y-0 lg:pl-16">
              <div className="w-full mt-12 lg:mt-0">
                <ImageContainer src={'/image/services/GiftGold.jpg'} alt="service1" className="" />
              </div>
            </div>
          </div>
        </div>

        <div className="pt-20 pb-24">
          <div className="flex items-center justify-center">
            <p className="text-3xl font-semibold text-[#010103]/80 md:text-5xl">{getLocaleString('benefit', locale)}</p>
          </div>
          <div className="flex items-center justify-center w-full pt-9 md:px-0 lg:px-40">
            <div className="space-y-10">
              <div>
                <div className="flex space-x-1 md:space-x-4">
                  <ImageContainer src="/image/services/check.svg" alt="check" className="" />
                  <p className="whitespace-nowrap pb-4 text-lg font-semibold text-[#010103]/80 md:text-xl lg:text-2xl">
                    {getLocaleString('buy_save_comfort', locale)}
                  </p>
                </div>
                <p className="text-[#010103]/54 px-10 text-base font-normal md:px-12 text-justify">
                  {getLocaleString('buy_save_comfort_description', locale)}
                </p>
              </div>
              <div>
                <div className="flex space-x-1 md:space-x-4">
                  <ImageContainer src="/image/services/check.svg" alt="check" className="" />
                  <p className="whitespace-nowrap pb-4 text-lg font-semibold text-[#010103]/80 md:text-xl lg:text-2xl">
                    {getLocaleString('store_with_assurance', locale)}
                  </p>
                </div>
                <p className="text-[#010103]/54 px-10 text-base font-normal md:px-12 text-justify">
                  {getLocaleString('store_with_assurance_description', locale)}
                </p>
              </div>
              <div>
                <div className="flex space-x-1 md:space-x-4">
                  <ImageContainer src="/image/services/check.svg" alt="check" className="" />
                  <p className="whitespace-nowrap pb-4 text-lg font-semibold text-[#010103]/80 md:text-xl lg:text-2xl">
                    {getLocaleString('gain_safety', locale)}
                  </p>
                </div>
                <p className="text-[#010103]/54 px-10 text-base font-normal md:px-12 text-justify">
                  {getLocaleString('gain_safety_description', locale)}
                </p>
              </div>
              <div>
                <div className="flex space-x-1 md:space-x-4">
                  <ImageContainer src="/image/services/check.svg" alt="check" className="" />
                  <p className="whitespace-nowrap pb-4 text-lg font-semibold text-[#010103]/80 md:text-xl lg:text-2xl">
                    {getLocaleString('sell_fairness', locale)}
                  </p>
                </div>
                <p className="text-[#010103]/54 px-10 text-base font-normal md:px-12 text-justify">
                  {getLocaleString('sell_fairness_description', locale)}
                  
                </p>
              </div>
              <div>
                <div className="flex space-x-1 md:space-x-4">
                  <ImageContainer src="/image/services/check.svg" alt="check" className="" />
                  <p className="whitespace-nowrap pb-4 text-lg font-semibold text-[#010103]/80 md:text-xl lg:text-2xl">
                    {getLocaleString('redeem_essentiality', locale)}
                  </p>
                </div>
                <p className="text-[#010103]/54 px-10 text-base font-normal md:px-12 text-justify">
                  {getLocaleString('redeem_essentiality_description', locale)}
                </p>
              </div>
            </div>
          </div>
          {/* <div className="px-0 lg:flex pt-9 lg:items-center lg:justify-center lg:px-24">
            <div className="grid grid-cols-1 gap-2 md:gap-6 lg:gap-10 lg:grid-cols-3">
              <div>
                <div className="flex space-x-2 md:space-x-4">
                  <ImageContainer src="/image/services/check.svg" alt="check" className="" />
                  <p className="pb-4 text-2xl font-semibold text-[#010103]/80">
                    Buy and Save in Comfort
                  </p>
                </div>

                <p className="text-[#010103]/54 px-12 text-base font-normal">
                  BUY gold from as low as BDT 500 and build your savings as you go. Save according
                  to your personal affordability, when you want, in any amount you want.
                </p>
              </div>
              <div>
                <div className="flex space-x-4">
                  <ImageContainer src="/image/services/check.svg" alt="check" className="" />

                  <p className="pb-4 text-2xl  font-semibold text-[#010103]/80">
                    Store with Assurance
                  </p>
                </div>

                <p className="text-[#010103]/54 px-12 text-base font-normal">
                  Store gold in GOLD KINEN’s insured vaults. Any gold you buy with us is stored in
                  our trusted vault partners. Your gold is fully insured against all risks at full
                  replacement value.
                </p>
              </div>
              <div>
                <div className="flex space-x-4">
                  <ImageContainer src="/image/services/check.svg" alt="check" className="" />

                  <p className="pb-4 text-2xl  font-semibold text-[#010103]/80">Gain in Safety</p>
                </div>

                <p className="text-[#010103]/54 px-12 text-base font-normal">
                  Enjoy fruitful capital gains with your stored gold. Gold savings strengthen during
                  inflation and economic downfalls, making GOLD a very low-risk savings option for
                  long-term financial protection.
                </p>
              </div>
            </div>
          </div>
          <div className="px-0 lg:flex pt-9 lg:items-center lg:justify-center lg:px-24">
            <div className="grid grid-cols-1 gap-2 px-0 md:gap-6 lg:gap-10 lg:grid-cols-2 lg:px-56">
              <div>
                <div className="flex space-x-4">
                  <ImageContainer src="/image/services/check.svg" alt="check" className="" />
                  <p className="py-4 text-2xl font-semibold text-[#010103]/80">Sell in Fairness</p>
                </div>
                <p className="text-[#010103]/54 px-12 text-base font-normal">
                  Sell your bought gold in international Live Market rates available in the app.
                  Reselling your bought gold in the app to GOLD KINEN will only amount to 15% Khaad
                  deduction at sale.
                </p>
              </div>
              <div>
                <div className="flex space-x-4">
                  <ImageContainer src="/image/services/check.svg" alt="check" className="" />
                  <p className="py-4 text-2xl font-semibold text-[#010103]/80">
                    Redeem in Essentiality
                  </p>
                </div>
                <p className="text-[#010103]/54 px-12 text-base font-normal">
                  Redeem gold in bar and coin format starting from 2 grams (please note, redeemed
                  gold cannot be sold/resold via GOLD KINEN APP).
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Services;
