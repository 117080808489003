import { bn_locale, en_locale } from "../locales";

export const getLocaleString = (string_index: string, locale: string) => {
  const locale_strings = locale === "en" ? en_locale : bn_locale as any;
  return locale_strings[string_index];
};

export const getLocaleObjKey = (obj: any, key: string, locale: string) => {
  return locale === 'en' ? `${key}` : obj[`${key}_bn`] ? `${key}_bn` : `${key}`;
}

export const getLocalAmenityKey = (locale: string) => {
  return locale === 'en' ? `eng` : `bng`;
}