import React, { useRef, useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { Link, NavLink } from 'react-router-dom';
// import CustomModal from './CustomModal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AiFillCloseSquare } from 'react-icons/ai';
import { LANGUAGE } from '../../constants/localStorage';
import { getData } from '../../services/storage';
import { getLocaleString } from '../../utils/locales';
import ImageContainer from './ImageContainer';
import LanguageSelect from './languageSelect';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '2rem 1rem',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);
function Navbar() {
  const locale = getData(LANGUAGE);
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const handleLinkOnClick = () => setIsOpen(false);
const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
console.log('language=> ', locale)
  return (
    <nav>
      

      <div
        style={{ backdropFilter: 'blur(2px)' }}
        className="fixed top-0 left-0 right-0 z-50 bg-[#081939] py-2 shadow-lg"
      >
        <div
          ref={ref}
          className="relative overflow-hidden transition-all duration-500 ease-in-out md:px-20 lg:px-24"
          style={{ maxHeight: isOpen && ref.current ? 800 : 60 }}
        >
          <div className="flex justify-between">
            <div className="ml-2 lg:mr-2">
              <Link  to={`/${locale}`} onClick={() => window.scrollTo(0, 0)}>
                <ImageContainer
                  src="/image/navbar/gklogo.png"
                  alt="gklogo"
                  className="object-scale-down h-8 w-18 lg:h-auto lg:w-44 md:max-w-md xs:object-fill"
                />
              </Link>
            </div>
            {/* Medium screen links STARTS */}
            <div className="hidden lg:flex lg:text-lg">
              <div className={`flex ${locale==='bn'?'space-x-3':'space-x-6'}`}>
                <NavLink
                  to={`${locale}/services`}
                  className="mt-4 space-y-2 text-sm font-medium text-center text-white hover:text-[#e9be5a] whitespace-nowrap"
                  style={({ isActive }) =>
                    isActive
                      ? {
                          textDecoration: 'underline #e9be5a 2px',
                          textUnderlineOffset: '6px',
                        }
                      : {}
                  }
                >
                  {getLocaleString('services_benefits', locale)}
                </NavLink>
                <NavLink
                  to={`${locale}/gold-kinen`}
                  className=" mt-4 text-sm font-medium text-center text-white hover:text-[#e9be5a] whitespace-nowrap"
                  style={({ isActive }) =>
                    isActive
                      ? {
                          textDecoration: 'underline #e9be5a 2px',
                          textUnderlineOffset: '6px',
                        }
                      : {}
                  }
                >
                  {getLocaleString('why_gold_kinen', locale)}
                </NavLink>

                

                {/* <a
                  href={`/${getData(LANGUAGE)}/#how-it-works`}
                  
                  className=" mt-4 text-sm font-medium text-center text-white hover:text-[#e9be5a] whitespace-nowrap"
                >
                  {getLocaleString('how_it_works', locale)}
                </a> */}

                <NavLink
                  to={`${locale}/how-it-works`}
                  className=" mt-4 text-sm font-medium text-center text-white hover:text-[#e9be5a] whitespace-nowrap"
                  style={({ isActive }) =>
                    isActive
                      ? {
                          textDecoration: 'underline #e9be5a 2px',
                          textUnderlineOffset: '6px',
                        }
                      : {}
                  }
                >
                  {getLocaleString('how_it_works', locale)}
                </NavLink>

                <NavLink
                  to={`${locale}/about-us`}
                  className=" mt-4 text-sm font-medium text-center text-white hover:text-[#e9be5a] whitespace-nowrap"
                  style={({ isActive }) =>
                    isActive
                      ? {
                          textDecoration: 'underline #e9be5a 2px',
                          textUnderlineOffset: '6px',
                        }
                      : {}
                  }
                >
                  {getLocaleString('about_us', locale)}
                </NavLink>

                <NavLink
                  to={`${locale}/faq`}
                  className=" mt-4 text-sm font-medium text-center text-white hover:text-[#e9be5a] whitespace-nowrap"
                  style={({ isActive }) =>
                    isActive
                      ? {
                          textDecoration: 'underline #e9be5a 2px',
                          textUnderlineOffset: '6px',
                        }
                      : {}
                  }
                >
                  {getLocaleString('faq', locale)}
                </NavLink>

                <NavLink
                  to={`${locale}/contact-us`}
                  className={`${locale==='bn'&&'pr-2'} mt-4 text-sm font-medium text-center text-white hover:text-[#e9be5a] whitespace-nowrap`}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          textDecoration: 'underline #e9be5a 2px',
                          textUnderlineOffset: '6px',
                        }
                      : {}
                  }
                >
                  {getLocaleString('contact_us', locale)}
                </NavLink>


                <a>
                  <button
                    // onClick={() => setShowSearchModal(true)}
                    className="py-4 text-base font-semibold rounded-lg bg-gradient-to-r from-[#e9be5a] to-[#c6952c] hover:bg-gradient-to-br focus:outline-none px-14 text-primary whitespace-nowrap hover:text-white"
                  onClick={handleOpen}>
                    {getLocaleString('download_app', locale)}
                  </button>
                </a>
                <LanguageSelect/>
                <div className="flex mt-4 space-x-2">
                  <span className="mt-1 text-white">
                    <BsFillTelephoneFill size={14} />
                  </span>
                  <span className="text-base font-normal text-white"><a href="tel:09610-964653">09610-964653</a></span>
                </div>
              </div>
            </div>
            {/* Medium screen links ENDS */}
            <div className="flex justify-end space-x-4 lg:hidden">
              {/* <a>
                  <button className="py-4 text-base font-semibold rounded-lg bg-secondary px-14 text-primary whitespace-nowrap">
                    Download App
                  </button>
                </a> */}

              <button
                type="button"
                className="p-2 text-white transition duration-500 ease-in-out cursor-pointer lg:hidden"
                onClick={() => setIsOpen(!isOpen)}
              >
                {isOpen ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
              </button>
            </div>
          </div>
          {/* small screen links STARTS */}
          {isOpen && (
            <div className="p-2 my-4 space-y-2 rounded-lg lg:hidden">
              <div>
                {/* <a
                  onClick={handleLinkOnClick}
                  href="/services"
                  className="px-2 mt-2 text-sm font-medium text-white"
                >
                  Services & Benefits
                </a> */}

                <NavLink
                  to={`${locale}/services`}
                   onClick={handleLinkOnClick}
                  className="px-2 mt-2 text-sm font-medium text-white"
                  style={({ isActive }) =>
                    isActive
                      ? {
                          textDecoration: 'underline #e9be5a 2px',
                          textUnderlineOffset: '6px',
                        }
                      : {}
                  }
                >
                  {getLocaleString('services_benefits', locale)}
                </NavLink>
              </div>

              
              <div>
                {/* <a
                  onClick={handleLinkOnClick}
                  href="/gold-kinen"
                  className="px-2 mt-2 text-sm font-medium text-white"
                >
                  Why Gold Kinen
                </a> */}
                <NavLink
                  to={`${locale}/gold-kinen`}
                  className="px-2 mt-2 text-sm font-medium text-white"
                  onClick={handleLinkOnClick}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          textDecoration: 'underline #e9be5a 2px',
                          textUnderlineOffset: '6px',
                        }
                      : {}
                  }
                >
                  {getLocaleString('why_gold_kinen', locale)}
                </NavLink>
              </div>
              <div>
                {/* <a
                  onClick={handleLinkOnClick}
                  href={`/${getData(LANGUAGE)}/#how-it-works`}
                  className="px-2 mt-2 text-sm font-medium text-white"
                >
                  {getLocaleString('how_it_works', locale)}
                </a> */}

                <NavLink
                  to={`${locale}/how-it-works`}
                  className="px-2 mt-2 text-sm font-medium text-white"
                  onClick={handleLinkOnClick}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          textDecoration: 'underline #e9be5a 2px',
                          textUnderlineOffset: '6px',
                        }
                      : {}
                  }
                >
                  {getLocaleString('how_it_works', locale)}
                </NavLink>
              </div>
              <div>
                <NavLink
                  to={`${locale}/about-us`}
                  className="px-2 mt-2 text-sm font-medium text-white"
                  onClick={handleLinkOnClick}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          textDecoration: 'underline #e9be5a 2px',
                          textUnderlineOffset: '6px',
                        }
                      : {}
                  }
                >
                  {getLocaleString('about_us', locale)}
                </NavLink>
              </div>
              <div>
                <NavLink
                  to={`${locale}/faq`}
                  className="px-2 mt-2 text-sm font-medium text-white"
                  onClick={handleLinkOnClick}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          textDecoration: 'underline #e9be5a 2px',
                          textUnderlineOffset: '6px',
                        }
                      : {}
                  }
                >
                  {getLocaleString('faq', locale)}
                </NavLink>
              </div>
              <div>
                <NavLink
                  to={`${locale}/contact-us`}
                  className="px-2 mt-2 text-sm font-medium text-white"
                  onClick={handleLinkOnClick}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          textDecoration: 'underline #e9be5a 2px',
                          textUnderlineOffset: '6px',
                        }
                      : {}
                  }
                >
                  {getLocaleString('contact_us', locale)}
                </NavLink>
              </div>
              <LanguageSelect/>
              <div>
                <a>
                  <button className="px-6 py-4 text-base font-semibold rounded-lg bg-gradient-to-r from-[#e9be5a] to-[#c6952c] hover:bg-gradient-to-br text-primary" onClick={handleOpen}>
                    {getLocaleString('download_app', locale)}
                  </button>
                </a>
              </div>
              <div className="flex mt-4 space-x-2">
                  <span className="mt-1 text-white">
                    <BsFillTelephoneFill size={14} />
                  </span>
                  <span className="text-base font-normal text-white"><a href="tel:09610-964653">09610-964653</a></span>
                </div>
            </div>
          )}
          {/* small screen links ENDS */}
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
         <div className="bg-white border rounded-2xl focus:outline-none">
          <div className="w-auto">
            <div className="flex items-center justify-between px-6 py-4">
              <p className="pr-4 text-lg font-semibold">{getLocaleString('download_app', locale)}</p>
              <div
                className="cursor-pointer bg-white border border-gray-100 inline-block p-2.5 rounded-full shadow-md hover:shadow-lg transition duration-300 ease-in-out"
                onClick={handleClose}
              >
                <AiFillCloseSquare
                  name="cross"
                  className="h-4 text-black fill-current stroke-current stroke-1"
                />
              </div>
            </div>
            <div className="p-6 overflow-auto text-center" style={{ maxHeight: '70vh' }}>
              <div className="pb-4 text-sm flex space-x-5 font-normal leading-6 text-[#FFFFFF]/50">
              {/* Bangladesh’s First Hallmark Certified 22k Gold Buy, Sell and Storage App. Download
              today to Buy Gold for as low as 500 BDT and Refer for a chance to win Gold for
              yourself. */}
              <a href='https://play.google.com/store/apps/details?id=com.goldkinen.android' target="_blank"><ImageContainer src="/image/navbar/Play Store Icon (Blue).png" alt="Play Store" className="h-16 lg:h-11" /></a>
              <a href='https://apps.apple.com/bn/app/gold-kinen/id6443875166' target="_blank"><ImageContainer src="/image/navbar/App Store Icon (Blue).png" alt="App Store" className="h-16 lg:h-11" /></a>
            </div>

            </div>
          </div>
        </div>
        </Fade>
      </Modal>
    </nav>
  );
}

export default Navbar;
