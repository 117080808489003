export function romanize (num: any) {
    if (isNaN(num))
        return NaN;
    var digits: any[] = String(+num).split(""),
        key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
               "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
               "","I","II","III","IV","V","VI","VII","VIII","IX"],
        roman = "",
        i = 3;
    while (i--)
        roman = (key[+digits.pop() + (i * 10)] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
}

export function numberToAlphabet(value: any) {
    return (value + 9).toString(36).toUpperCase()
}

export function engToBdNum(str: any, locale: string) {
    return locale == 'bn' ? str.toString().replace(/\d/g, (d: number) => "০১২৩৪৫৬৭৮৯"[d]) : str
};