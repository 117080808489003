
import { LANGUAGE } from '../../constants/localStorage';
import { getData } from '../../services/storage';
import { getLocaleString } from '../../utils/locales';
const TermsOfUse = () => {
const locale = getData(LANGUAGE);

  return (
    <div style={{display:'flex', justifyContent: 'center'}}>
      {/* <iframe src="https://drive.google.com/file/d/1gtSKuL8SnLlJyWSHL6DeJKxORM32wqf2/preview" width="640" height="480" allow="autoplay"></iframe> */}
      <iframe src={getLocaleString('termsConditionDoc', locale)} width="100%" height="700px"/>
    </div>
  )
}
export default TermsOfUse